import React, {useRef, useEffect} from 'react';
import { MDXProvider } from '@mdx-js/react';
import ThemeProvider from '../theme/themeProvider';
import mdxComponents from '../mdxComponents';
import LeftSidebar from '../sidebar/LeftSidebar';
import RightSidebar from '../sidebar/RightSidebar';
import Footer from '../footer/Footer';
import Breadcrumbs from '../breadcrumb/Breadcrumbs';
import NextPrevious from '../next_previous/NextPrevious';
import DisqusCommentsTemplate from '../comments/DisqusCommentsTemplate';
import { usePageMetaData } from '../hooks/usePageMetaData';
import ScrollToTop from '../scroll/ScrollToTop';
import PostTags from '../tags/postTags';
import { Content, LeftSideBarWidth, MaxWidth, RightSideBarWidth, Wrapper } from './layoutStyles';
import { GoogleAdSense } from '../ads/GoogleAdSense';
import { AmazonPrime } from '../affiliate/AmazonPrime';

const defaultEdgeGroupId = "NONE" ;

const BlogPageLayout = ({ children, location, pageContext, isPost }) => {

  const scrolled = useRef(null)

  useEffect(() => {
    const headID = document.getElementsByTagName('head')[0]

    document.addEventListener('scroll', function (e) {
        if (!scrolled.current) {
            scrolled.current = true

            // Google Ads
            const gaScript = document.createElement('script')
            gaScript.async = true
            gaScript.crossOrigin = 'anonymous'
            gaScript['data-ad-client'] = '1531009424550706'
            gaScript.src =
                'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
            gaScript.type = 'text/javascript'
            headID.appendChild(gaScript)
        }
    })
  }, [])

  const isPostPage = isPost === false ? false : true ;

  // console.log("location >>", location)
  // console.log("pageContext >>", pageContext)
  // console.log("isPost >>", isPost)

  let edges = undefined
  let tocItems = undefined
  let edge ;
  let edgeGroupId = defaultEdgeGroupId;
  let tags = undefined

  if (isPostPage) {
    const {allMdx} = usePageMetaData()
    var pageMetaDataMap = {};
    var slugPrevNextInfoMap = {};

    edges = allMdx.edges
    
    let prev ;
    let next ;

    edges.map((edge, index) => {
      prev = edges[index-1]
      next = edges[index+1]
      pageMetaDataMap[edge.node.fields.slug] = edges[index]
      slugPrevNextInfoMap[edge.node.fields.slug] = {
        prev : prev,
        next : next,
      }
    })
    let pathname = location.pathname
    edge = pageMetaDataMap[pathname]
    if (edge === undefined || edge == undefined ) {
      pathname =  pathname.endsWith('/') ?
                  pathname.replace(/.$/,"") : 
                  pathname
      edge = pageMetaDataMap[pathname]
      // console.log("edge", pathname)
    } 
    // console.log("pageDataMap", pageMetaDataMap)
    // console.log("pathname", pathname)
    // console.log("slugPrevNextInfoMap >>> ", slugPrevNextInfoMap)

    tags = edge.node.frontmatter.tags
    edgeGroupId = edge.node.frontmatter.groupId
    tocItems= edge.node.tableOfContents.items 
  }

  

  return( 
  <ThemeProvider location={location} edges={edges} edgeGroupId={edgeGroupId}>
    <MDXProvider components={mdxComponents}>
      <Wrapper>
        <LeftSideBarWidth className={'hiddenMobile'}>
          <LeftSidebar location={location} edges={edges} edgeGroupId={edgeGroupId}/>
        </LeftSideBarWidth>
        {/* {config.sidebar.title ? (
          <div
            className={'sidebarTitle sideBarShow'}
            dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
          />
        ) : null} */}
        <Content>
          <MaxWidth>
            <AmazonPrime location={location}/>
            <div><br/><br/></div>
            <GoogleAdSense location={location}/>
            <div><br/><br/></div>
            {
              edgeGroupId != defaultEdgeGroupId ? 
              <Breadcrumbs pageContext={pageContext}/> : null
            }
            {
              edgeGroupId != defaultEdgeGroupId ? 
              <div className={'addPaddTopBottom'}>
                <NextPrevious slugPrevNextInfoMap={slugPrevNextInfoMap} edge={edge} />
              </div> : null
            }
            {
              edgeGroupId != defaultEdgeGroupId ? 
              <>
                <div> &nbsp; <br/></div>
                <PostTags tags={tags} isPost={isPost}/>
              </> : null
            }
            <div> &nbsp; <br/></div>
            {children}
            <div> &nbsp; <br/> <br/> </div>
            {
              edgeGroupId != defaultEdgeGroupId ? 
              <PostTags tags={tags}/> : null
            }
            <div> &nbsp; <br/></div>
            <GoogleAdSense location={location}/>
            {
              edgeGroupId != "NONE" ?
              <div className={'addPaddTopBottom'}>
                <NextPrevious slugPrevNextInfoMap={slugPrevNextInfoMap} edge={edge} />
              </div> : null
            }
            {/* <div class="fb-comments" data-href="https://technologystrive.com" data-width="" data-numposts="5">
            </div> */}
            <ScrollToTop showBelow={250} />
            <GoogleAdSense location={location}/>
            <DisqusCommentsTemplate location={location}/>
            <GoogleAdSense type={"MULTIPLEX"} />
          </MaxWidth>
        </Content>
        <RightSideBarWidth className={'hiddenMobile'}>
            <br/> <br/> <br/> <br/> <br/> <br/>  <br/>
          <RightSidebar tocItems={tocItems} location={location} />
        </RightSideBarWidth>
        
      </Wrapper>
    </MDXProvider>
    <Footer/>
  </ThemeProvider>
  );
  }

export default BlogPageLayout;
